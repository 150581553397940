import { Component, OnInit } from '@angular/core';
import {
  NavController,
  NavParams, Events, LoadingController, AlertController, PopoverController
} from '@ionic/angular';
// import { FormGroup, FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { ViewController } from '@ionic/core';
// import { ViewController } from '@ionic/core';
/**
 * Generated class for the LoginPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  // public loginForm: FormGroup; 
  private pageCount: number;

  private uid: string; //hold my user id 

  private message: any;

  constructor(
    public navCtrl: NavController,
    public authService: AuthService,
    public popoverCtrl: PopoverController

  ) {
    // this.loginForm = formBuilder.group({
    //   email: ['',
    //   Validators.compose([Validators.required, EmailValidator.isValid])],
    //   password: ['',
    //   Validators.compose([Validators.minLength(6), Validators.required])]
    // });

    // get user id or ip and record view 
    // let user_self = this.authService.getCurrentUser();
    // if (user_self) {
    //   this.uid = user_self.uid;
    // } else {
    //   this.uid = null;
    //   // console.log('you have to login first');
    // }

  }

  ngOnInit() {
    // this.authService.addViewHistory('login','')
    // this.authService.addClickHistory('login', '','pageEner','','')
  }

  // loginUser(): void {
  //   this.pageCount = this.navParams.get('data');
  //   if (!this.loginForm.valid){
  //     console.log(this.loginForm.value)
  //   } else {
  //     this.authService.loginUser(this.loginForm.value.email,
  //       this.loginForm.value.password)
  //     .then( authData => {
  //       this.loading.dismiss().then( () => {
  //         //this.navCtrl.parent = TabsPage;

  //         let user = this.authService.getCurrentUser();
  //         if(user && user.emailVerified){
  //           this.add_click_history('button-login','success')
  //           //this.events.publish('emailVerified');
  //           //this.navCtrl.parent = TabsPage;
  //         } else {
  //             this.authService.logoutUser();
  //             let alert = this.alertCtrl.create({

  //               title: 'You need verify your email address first',
  //               buttons: ['Ok']
  //             });

  //             alert.present();
  //             this.add_click_history('button-login','need-vefification')
  //         }
  //         // if(this.pageCount==1){
  //         //   this.navCtrl.setRoot(AddVideoPage);
  //         // } else {
  //         //   this.navCtrl.setRoot(UserProfilePage);
  //         // }
  //       });
  //     }, error => {
  //       this.loading.dismiss().then( () => {
  //         let alert = this.alertCtrl.create({
  //           message: error.message,
  //           buttons: [
  //             {
  //               text: "Ok",
  //               role: 'cancel'
  //             }
  //           ]
  //         });
  //         alert.present();
  //       });
  //     });
  //     this.loading = this.loadingCtrl.create();
  //     this.loading.present();
  //   }
  // }
  goToLoginEmail() {
    // this.authService.addClickHistory('login', '','closePage','open-login-email','') 
    // this.viewCtrl.dismiss({'loginStatus':'openLoginEmail'}) 
  }
  // goToSignup(): void {
  //   var navOptions = {
  //     animation: 'md-transition'
  //   };
  //   this.add_click_history('open-sign-up','')
  //   this.navCtrl.push(SignupPage,{},navOptions);
  // }

  // goToResetPassword(): void {
  //   this.add_click_history('button-reset-password','')
  //   this.navCtrl.push(ResetPasswordPage);
  // }

  loginWithFacebook() {
    //this.pageCount = this.navParams.get('data');  
    // this.authService.addClickHistory('login', '','button','login-facebook','try') 
    this.authService.loginWithFacebook().then(() => {
      let user = this.authService.getCurrentUser()
      if (user) {
        this.popoverCtrl.dismiss({ 'loginStatus': true, 'displayName': user.displayName })
        // this.authService.addClickHistory('login', '','button','login-facebook','success')  
      }
    });
  }
  // logoutOfFacebook(){
  //   this.authService.logoutUser()
  // }
  loginWithGoogle() {
    // this.pageCount = this.navParams.get('data'); 
    // this.authService.addClickHistory('login', '','button','login-google','try') 

    this.authService.loginWithGoogle().then(() => {
      let user = this.authService.getCurrentUser()
      if (user) {
        // this.authService.addClickHistory('login', '','button','login-google','success') 
        this.popoverCtrl.dismiss({ 'loginStatus': true, 'displayName': user.displayName })
      }
    })
  }

  openTOS() {
    this.popoverCtrl.dismiss()
  }
  openPP() {
    this.popoverCtrl.dismiss()
  }
  goBack() {
    // this.authService.addClickHistory('login', '', 'closePage', '', '')
    this.popoverCtrl.dismiss({ 'loginStatus': false, 'displayName': '' })
  }
}
