import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-view-map-in-app',
  templateUrl: './view-map-in-app.component.html',
  styleUrls: ['./view-map-in-app.component.scss']
})
export class ViewMapInAppComponent implements OnInit {

  constructor(private platform: Platform,
    public authService:AuthService) { }

  ngOnInit() {
  }
  openDownloadLink(){
    if (this.platform.is('ios')) {
      window.location.href = 'https://itunes.apple.com/us/app/placetell-maps-of-videos/id1450520695?mt=8&ign-mpt=uo%3D2'
      // window.open(url, "_blank");
      this.authService.addClickHistory('','','open-download-link','ios','' )

    } else if (this.platform.is('android')) {
      window.open('https://play.google.com/store/apps/details?id=com.placetell.placetell', "_blank");
      this.authService.addClickHistory('','','open-download-link','android','' )
    
    }
  }
}
