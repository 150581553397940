import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
  name: 'youtube'
})
export class YoutubePipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer){

  }
  /**
   * Takes a value and makes it lowercase.
   */
  transform(value, args) {
    //return value.toLowerCase();
    // let dangerousVideoUrl = 'https://www.youtube.com/embed/' + value +'?start='+args+'&autoplay=1&rel=0&playsinline=1';

    let dangerousVideoUrl = 'https://www.youtube.com/embed/' + value +'?playsinline=1';
    return this.sanitizer.bypassSecurityTrustResourceUrl(dangerousVideoUrl);
  }

}
