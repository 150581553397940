import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  { path: 'home', loadChildren: './pages/home2/home2.module#Home2PageModule' },
  { path: 'home/:source', loadChildren: './pages/home2/home2.module#Home2PageModule' },
  { path: 'terms-of-service', loadChildren: './pages/terms-of-service/terms-of-service.module#TermsOfServicePageModule' },
  { path: 'privacy-policy', loadChildren: './pages/privacy-policy/privacy-policy.module#PrivacyPolicyPageModule' },
  { path: 'disclaimer', loadChildren: './pages/disclaimer/disclaimer.module#DisclaimerPageModule' },
  { path: 'about', loadChildren: './pages/about/about.module#AboutPageModule' },
  { path: 'story/:id', loadChildren: './pages/story/story.module#StoryPageModule' },
  { path: 'story/:id/:source', loadChildren: './pages/story/story.module#StoryPageModule' },
  { path: 'tag/:id/:source', loadChildren: './pages/tag2/tag2.module#Tag2PageModule' },
  { path: 'tag/:id', loadChildren: './pages/tag2/tag2.module#Tag2PageModule' },
  { path: 'news', loadChildren: './pages/news/news.module#NewsPageModule' },
  { path: 'nearby', loadChildren: './pages/nearby2/nearby2.module#Nearby2PageModule' },
  { path: 'tour/:id', loadChildren: './pages/tour/tour.module#TourPageModule' },
  { path: 'campustour/:id', loadChildren: './pages/campustour/campustour.module#CampustourPageModule' },
  { path: 'campustours', loadChildren: './pages/campustours/campustours.module#CampustoursPageModule' },
  { path: 'tours', loadChildren: './pages/tours/tours.module#ToursPageModule' },
  { path: 'city/:id', loadChildren: './pages/city2/city2.module#City2PageModule' },
  { path: 'city/:id/:category', loadChildren: './pages/city2/city2.module#City2PageModule' },
  { path: 'cities', loadChildren: './pages/cities/cities.module#CitiesPageModule' },
  { path: 'stories', loadChildren: './pages/stories/stories.module#StoriesPageModule' },
  { path: 'business', loadChildren: './pages/business/business.module#BusinessPageModule' },
  { path: 'business/virtualtours', loadChildren: './pages/business-virtualtours/business-virtualtours.module#BusinessVirtualtoursPageModule' },
  { path: 'account', loadChildren: './pages/account/account.module#AccountPageModule' },
  { path: 'user/:id', loadChildren: './pages/user/user.module#UserPageModule' },
  { path: 'edit/:id', loadChildren: './pages/edit/edit.module#EditPageModule' },
  { path: 'pin-a-video', loadChildren: './pages/pin-a-video/pin-a-video.module#PinAVideoPageModule' },
  { path: 'new/:id', loadChildren: './pages/new/new.module#NewPageModule' },
  { path: 'video-modal', loadChildren: './pages/video-modal/video-modal.module#VideoModalPageModule' },
  { path: 'video/:id', loadChildren: './pages/video/video.module#VideoPageModule' },
  { path: 'video', loadChildren: './pages/home/home.module#HomePageModule' },
  { path: 'moviemap', loadChildren: './pages/moviemap/moviemap.module#MoviemapPageModule' },
  { path: 'media/:id/:source', loadChildren: './pages/media/media.module#MediaPageModule' },
  { path: 'media/:id', loadChildren: './pages/media/media.module#MediaPageModule' },
  { path: 'foodmap', loadChildren: './pages/foodmap/foodmap.module#FoodmapPageModule' },
  { path: 'musicmap', loadChildren: './pages/musicmap/musicmap.module#MusicmapPageModule' },
  { path: 'nearby2', loadChildren: './pages/nearby2/nearby2.module#Nearby2PageModule' },
  // { path: 'city2', loadChildren: './pages/city2/city2.module#City2PageModule' },
  { path: 'city2/:id', loadChildren: './pages/city2/city2.module#City2PageModule' },
  { path: 'city2/:id/:category', loadChildren: './pages/city2/city2.module#City2PageModule' },
  { path: 'tag2/:id', loadChildren: './pages/tag2/tag2.module#Tag2PageModule' },
  { path: 'home2', loadChildren: './pages/home2/home2.module#Home2PageModule' },
  // { path: 'video/:id', loadChildren: './pages/video/video.module#VideoPageModule' },
  // { path: 'video/:id/:source', loadChildren: './pages/video/video.module#VideoPageModule' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
