import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/auth';

import { ComponentsModule } from './components/components.module';
// import { YoutubePipe } from './pipes/youtube.pipe'
import { PipesModule } from './pipes/pipes.module'
import { HttpClientModule } from '@angular/common/http';
import { VideoPageModule } from './pages/video/video.module';
import { FormsModule } from '@angular/forms'
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { LoginComponent } from './components/login/login.component';
import { AngularFireDatabaseModule, AngularFireDatabase } from '@angular/fire/database';
import { AngularFireModule } from '@angular/fire';
import { IonicStorageModule } from '@ionic/storage';
import { LocalService } from './services/local.service';
import { VideoModalPageModule } from './pages/video-modal/video-modal.module';
import  { AgmCoreModule} from '@agm/core'
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { GoogleMapsAPIWrapper} from '@agm/core'; 


export const firebaseConfig = {
  apiKey: "AIzaSyAL9qi_QUgAaSdAT-ETavfCNs-doGxGa-s",
  authDomain: "placetell-001.firebaseapp.com",
  databaseURL: "https://placetell-001.firebaseio.com",
  projectId: "placetell-001",
  storageBucket: "placetell-001.appspot.com",
  messagingSenderId: "748651610408"
};

@NgModule({
  declarations: [AppComponent],
  entryComponents: [
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AngularFireModule.initializeApp(firebaseConfig),
    AppRoutingModule,
    ComponentsModule,
    PipesModule,
    HttpClientModule,
    VideoModalPageModule,
    FormsModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    IonicStorageModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey:'AIzaSyC0ftkTR0SHyIUVdXrL0ivxooj4VfcdyME'
    }),
    AgmSnazzyInfoWindowModule,
    AgmJsMarkerClustererModule

  ],
  providers: [

    StatusBar,
    SplashScreen,
    Geolocation,
    AngularFireDatabase,
    LocalService,
    GoogleMapsAPIWrapper,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
