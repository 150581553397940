import { Component, OnInit, Input } from '@angular/core';
import { NavController  } from '@ionic/angular';

@Component({
  selector: 'app-storycard',
  templateUrl: './storycard.component.html',
  styleUrls: ['./storycard.component.scss']
})
export class StorycardComponent implements OnInit {
  @Input() story: any;
  public devWidth = window.innerWidth;
  constructor(public navCtrl: NavController) { 
  }
    
  ngOnInit() {
    
    // console.log('story',this.story)
  }
  open_story(story){
    this.navCtrl.navigateRoot('story/'+story.key) 
  }
}
