import { Component } from '@angular/core';

import { Platform, NavController, MenuController, Events } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import * as firebase from 'firebase';
import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth'; 

export const firebaseConfig = {
  apiKey: "AIzaSyAL9qi_QUgAaSdAT-ETavfCNs-doGxGa-s",
  authDomain: "placetell-001.firebaseapp.com",
  databaseURL: "https://placetell-001.firebaseio.com",
  projectId: "placetell-001",
  storageBucket: "placetell-001.appspot.com",
  messagingSenderId: "748651610408"
};


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'Videos Nearby',
      url: '/nearby',
      icon: 'home'
    },
    {
      title: 'Virtual Tours',
      url: '/tours',
      icon: 'home'
    },
    {
      title: 'Cities',
      url: '/cities',
      icon: 'home'
    },
    {
      title: 'About Us',
      url: '/about',
      icon: 'home'
    },
    {
      title: 'Terms of Service',
      url: '/terms-of-service',
      icon: 'list'
    },
    {
      title: 'Privacy Policy',
      url: '/privacy-policy',
      icon: 'list'
    },
    {
      title: 'Disclaimer',
      url: '/disclaimer',
      icon: 'list'
    }
  ];

  devOS: any;
  private show_footer: any;
  public headerStyle: any;
  private visitorIP: any;
  private loginStatusReady: any;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public navCtrl: NavController,
    public http: HttpClient,
    public menu: MenuController,
    public events: Events,
    private afAuth: AngularFireAuth,
  ) { 
    this.visitorIP = 'unknown'
    this.headerStyle = { 'height': '60px', 'background': 'yellow' }
    // this.initializeApp(); 
    this.devOS = 'unknown'
    if (this.platform.is('ios')) {
      this.devOS = 'ios'
      // This will only print when on iOS
      console.log('I am an iOS device!');
    }
    if (this.platform.is('android')) {
      // This will only print when on Android
      this.devOS = 'android'
      console.log('I am an android device!');
    }
    if (window.innerWidth < 1024 && this.devOS != 'unknown') {
      this.show_footer = true;
    } else {
      this.show_footer = false;
    }
    this.http.get('https://jsonip.com').subscribe(res => {
      this.visitorIP = res['ip']
    })

  }

  initializeApp() {
    this.platform.ready().then(() => {
      // this.statusBar.styleDefault();
      // this.splashScreen.hide();
    });
    
    firebase.initializeApp(firebaseConfig);
  }


  add_click_history(type, key) {
    if (this.visitorIP != '74.77.85.36') {
      let currentTime = firebase.database.ServerValue.TIMESTAMP;
      let node = {
        'timestamp': currentTime,
        'ip': this.visitorIP,
        'page': '',
        'subpage': '',
        'pagename': '',
        'action': type,
        'actionKey': key,
        'source': 'mobile'
      }

      firebase.database().ref('/admin/clickHistory/').push(node)
    }

  }

  open_link(url) {
    if (this.platform.is('ios')) {
      window.location.href = url
      // window.open(url, "_blank");
    } else if (this.platform.is('android')) {
      window.open(url, "_blank");
    }
    this.add_click_history('open_banner-link', this.devOS)

  }
  openPage(name) {
    this.menu.close()
    this.navCtrl.navigateRoot('/' + name)
  }
  close_footer() {
    this.show_footer = false;
    this.headerStyle = { 'height': '0px' }
    this.add_click_history('close_banner', this.devOS)
  }
 }
