import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { NavController, AlertController, IonContent, NavParams, PopoverController, ActionSheetController, ModalController } from '@ionic/angular';
import { AuthService } from '../../services/auth.service';
// import { authService } from '../../providers/auth/auth';
// import { DataProvider } from '../../providers/data/data';
// import { FoldersPopoverComponent } from '../../components/folders-popover/folders-popover';
// import { ReportComponent } from '../../components/report/report';
// import { ReportCommentComponent } from '../../components/report-comment/report-comment';
// import { Geolocation } from '@ionic-native/geolocation';
// import { AddVideo_2Page} from '../add-video-2/add-video-2';
// import { EditorsPage } from '../editors/editors'; 
// import { SocialSharing } from '@ionic-native/social-sharing';
// import { NavigationPage } from '../navigation/navigation'; 
// import { App } from 'ionic-angular';
// import { AdMobFree, AdMobFreeBannerConfig, AdMobFreeInterstitialConfig } from '@ionic-native/admob-free';

declare var google;
@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.page.html',
  styleUrls: ['./video-modal.page.scss'],
})
export class VideoModalPage implements OnInit {
  @ViewChild(IonContent) content: IonContent;
  @ViewChild('mapVideoModal') mapRef: ElementRef;
  // @ViewChild('directionsPanel') directionsPanel: ElementRef;

  private video: any; // variable for holding the reference of video
  public videoId:any;
  public locations:any;
  
  
  
  
  private items = []; // variable for holding all videos from local storage

  private editor: any;
  private recordingDate: any;
  private tags: any;

  // private postors :postor ; // variable for holding the first postor of the video
  private url: any; // variable for holding img url from firebase
  private fire_comments: any; // variable for holding all comments from firebase
  private comments: any; // variable for loading scroll down comments
  private sub_comments: any // variable for holding all sub_comments from firebase

  private comments_count: number;

  private uid: string // variable for holding the reference of user's session
  private displayName: string // variable for holding the displayName of user from firebase

  private like: string; // variable for like system
  private fire_like: string;
  private like_count: number;

  // private thumb_up_color : string;

  private expand: boolean; // variable for hidden and expand system
  private show_sub_comments: any // variable for hidden and expand sub comments system
  private count: number; // variable for track number of items to load from scroll down
  private rep_comment: string; // variable for hidden and expand system for reply comments

  private map: any; // variable for holding the reference of google map
  private marker: any; // variable for holding the reference of google map marker

  private comment: string; // variable for two way binding of user's input

  private showPopBack: boolean;
  private mapType: any;

  private display: boolean;
  private videoCount: number;

  private visitorIP: any;
  private userDevice: any;

  private relatedVideos: any;
  constructor(public navCtrl: NavController, public navParams: NavParams,
    public authService: AuthService,
    public popoverCtrl: PopoverController,
    public modalCtrl: ModalController

    // public geolocation : Geolocation, 
    // public localStorage: DataProvider,
    // public viewCtrl: ViewController, 
    // private socialSharing: SocialSharing,
    // private actionSheetCtrl: ActionSheetController, private app: App,
    // public alertCtrl:AlertController,
    // public admob: AdMobFree
  ) {
    // this.video = this.navParams.get('video');
    this.videoId = this.navParams.get('video').videoId
    // this.get_video_poster();
    // this.addToLocalStorage();
    // this.get_comments();
    // this.get_comments_count();

  }
  ngOnInit() {
    this.init_variables();
    this.showMap();
    this.getVideoInfo()
    this.get_liked_count();
    
    this.getRelatedVideos(this.video.videoId)
  }

  ionViewDidEnter() { 
    // this.showMap();
  }
  ionViewWillLeave() {
    // let listaFrames = document.getElementsByTagName("iframe");
    // // console.log('here',listaFrames);
    // for (var index = 0; index < listaFrames.length; index++) {
    //     let iframe = listaFrames[index].contentWindow;
    //     iframe.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
    //     // console.log(index);
    //  } 
  }
  ionViewDidLeave() {
    // console.log('video page leave!');
    // // this.numberOfTimeLeave();
    // if(this.fire_like != this.like){
    //   this.fire_like = this.like;
    //   let node = {};
    //   node[this.uid] = true;
    //   let ref = this.authService.getRef('videosLike/'+this.video.videoId)
    //   if(this.fire_like=='placetellBlue'){
    //     ref.update(node);
    //     // console.log('send new change to db');
    //   } else {
    //     ref.child(this.uid).remove();
    //     // console.log('send new change to db');
    //   }
    // } else {
    //   // console.log('do nothing');
    // }

  }
  /* 1. initialize all variable for global scope
  */
  init_variables() {


    this.video = {}
    

    this.showPopBack = true;
    let bool = this.navParams.get('showPopBack')
    // console.log('bool',bool)
    if (bool == false) {
      this.showPopBack = false;
    }
    this.like = "light";
    this.fire_like = 'light';
    this.like_count = 0;
    this.comments_count = 0;
    this.expand = false;
    this.show_sub_comments = [];
    //this.postors = {'key':"",'displayName':"",'url':""};
    this.rep_comment = "";
    this.comment = "";
    this.count = 0;
    this.url = "assets/imgs/img.jpg";
    this.fire_comments = [];
    this.comments = [];
    this.sub_comments = {};
    this.recordingDate = this.video.date;
    this.tags = [];
    this.mapType = 'Road';
    // this.userDevice = this.authService.userDeviceType
    // if (this.authService.uIP) {
    //   this.visitorIP = this.authService.uIP
    //   this.add_view_count();
    // } else {
    //   setTimeout(() => {
    //     this.visitorIP = this.authService.uIP
    //     if (this.visitorIP == undefined) {
    //       this.visitorIP = 'unknown'
    //     }
    //     this.add_view_count();
    //   }, 500)
    // }
    // let user = this.authService.getCurrentUser();
    // if user is logined in, get his display name and img url
    // if(user){
    //   this.uid = user.uid;
    //   let ref = this.authService.getRef('userInfo/video').child(this.uid);
    //   let timestamp = this.authService.getCurrentTime();
    //   let node = {'timestamp':timestamp,'videoId':this.video.videoId};
    //   ref.push(node);
    //   this.authService.download(this.uid).then(url=>{
    //     this.url = url;
    //   }).catch(err=>{
    //     console.log('err');
    //   })
    //   let ref_displayName = this.authService.getRef('userProfile/'+this.uid+'/user_info/displayName');
    //   ref_displayName.once('value').then(displayName=>{
    //     this.displayName = displayName.val()
    //   })
    //   this.get_liked();
    // } else {
    //   this.uid = ""
    // }
  }
  // add_view_count() {
  //   if (this.visitorIP != '74.77.85.36') {
  //     let currentTime = this.authService.getCurrentTime();
  //     let node = {
  //       'timestamp': currentTime,
  //       'ip': this.visitorIP,
  //       'page': 'video',
  //       'subpage': this.video.videoId,
  //       'pagename': this.video.title,
  //       'action': 'pageEnter',
  //       'actionKey': '',
  //       'source': '',
  //       'device': this.userDevice,
  //       'screenWidth': screen.width,
  //       'screenHeight': screen.height
  //     }
  //     this.authService.getRef('/admin/clickHistory/').push(node)
  //     this.authService.getRef('/admin/viewHistory/all/').push(node)
  //   }
  // }


  // add_click_history(type, key) {
  //   if (this.visitorIP == undefined) {
  //     this.visitorIP = 'unknown'
  //   }
  //   if (this.visitorIP != '74.77.85.36') {
  //     let currentTime = this.authService.getCurrentTime();
  //     let node = {
  //       'timestamp': currentTime,
  //       'ip': this.visitorIP,
  //       'page': 'video',
  //       'subpage': this.video.videoId,
  //       'pagename': this.video.title,
  //       'action': type,
  //       'actionKey': key,
  //       'source': '',
  //       'device': this.userDevice,
  //       'screenWidth': screen.width,
  //       'screenHeight': screen.height
  //     }
  //     this.authService.getRef('/admin/clickHistory/').push(node)
  //   }
  // 
  // }
   
  getVideoInfo(){
    this.authService.getRef('videos/'+this.videoId).once('value').then(res=>{
      this.video = res.val()
      this.locations = res.val().locations
      this.addMarker({ 'lat': this.locations[0].lat, 'lng': this.locations[0].lng },this.map)
      this.map.setCenter({ 'lat': this.locations[0].lat, 'lng': this.locations[0].lng });
      this.authService.addViewHistory('video',this.videoId,this.video.title)
      
    })
  }
  notContain(item, items): boolean {
    for (let i = 0; i < items.length; i++) {
      if (item.videoId == items[i].videoId) {
        items[i] = item;
        return false;
      }
    }
    return true;
  }
  /* 1. create a google map, with a single marker with main location
  */
  showMap() {  
    // Map options
    const options = {  
      zoom: 16,
      streetViewControl: false,
      fullscreenControl: false,
      disableDefaultUI: false,
      mapTypeId: 'hybrid',
      gestureHandling: 'cooperative'
    }
    this.map = new google.maps.Map(this.mapRef.nativeElement, options); 
    
  }
  /* 1. add marker to google map according to the geo location passed into this function
  */
  addMarker(position, map) {
    return new google.maps.Marker({
      position: position,
      map: map,
    });
  }
  /* 1. create an marker at given location and pinto it on the google map
  */
  pinTo(location) {
    let latlng = { 'lat': location.lat, 'lng': location.lng };
    this.marker.setPosition(latlng);
    this.map.panTo(latlng);
    this.map.setZoom(10);
  }
  open_tag(tag) {
    // console.log(tag);
    this.modalCtrl.dismiss()
    this.navCtrl.navigateRoot('tag/' + tag.key)
    this.authService.addClickHistory('video',this.videoId,this.video.title,'open-tag',tag.name)

    // tag['img'] = 'assets/imgs/people/' + tag.name + '.jpg';
    // setTimeout(() => {
    //   this.navCtrl.navigateRoot('/tag/' + tag.key)
    // }, 300)

    // this.navCtrl.push(CelebrityPage,{'name':tag.name,'key':tag.key,'img':tag.img});
  }
  /* 1. function for like or not like in heart
  */
  likes() {
    if (this.uid) {
      if (this.like == 'placetellBlue') {
        this.like = 'light';
        this.like_count--;
      } else {
        this.like = 'placetellBlue';
        this.like_count++;
      }
    } else {
      return;
    }
  }
  /* 1. function for hidden or expand html element in UI
  */
  // more() {
  //   if(this.expand==false){
  //     this.expand = true;
  //   } else {
  //     this.expand = false;
  //   }
  // }
  /* 1. if user is already logined in, show all user's folder for choice of adding video to folder
  */
  // add() {
  //   if(this.uid){
  //     // get all folder from firebase based on this uid
  //     this.authService.getPublicFolder(this.uid).once('value').then(res=>{
  //       // create a popover window with all folders name
  //       let popover = this.popoverCtrl.create(FoldersPopoverComponent,{'folders':res.val()});
  //       popover.present();
  //       // when popvoer window closes, if there is choosed folder, add video to this folder
  //       popover.onDidDismiss(folderId =>{
  //         // console.log(folderId);
  //         if(folderId!==null && folderId!='undefined'){
  //           //console.log(folderId);
  //           if(folderId=="High Light"){
  //             let ref_to_highlight = this.authService.getRef('userProfile/'+this.uid+'/public_folders/High Light');
  //             ref_to_highlight.once('value').then(res=>{
  //               let children = res.numChildren();
  //               if(children==1){
  //                 // console.log('no video yet');
  //                 this.authService.insertDataWithHighLight(this.uid,this.video.videoId,1);
  //               } else if(children==2){
  //                 this.authService.insertDataWithHighLight(this.uid,this.video.videoId,2);
  //                 // console.log('has 1 video')
  //               } else {
  //                 //console.log(res.val());
  //                 let data = res.val();
  //                 data['1'] = data['2']
  //                 data['2'] = this.video.videoId;
  //                 //console.log(data);
  //                 this.authService.insertDataWithHighLight(this.uid,data,3);
  //                 // console.log('has more than 1 video');
  //               }
  //             })
  //           } else if(folderId == "My Favorites"){
  //             let currentTime = this.authService.getCurrentTime();
  //             //this.authService.insertDataWithFavority(this.uid,folderId,this.video.videoId);
  //             let ref = this.authService.getRef('/userProfile/'+this.uid+'/folders/My Favorites/'+this.video.videoId);
  //             ref.set({'timestamp':currentTime});
  //             ref.once('value').then(snapshot=>{
  //               let timestamp = snapshot.val().timestamp * -1
  //               ref.update({'timestamp':timestamp});
  //             })
  //             //let node = {'content':this.comment,'userId':this.uid,'timestamp':currentTime,'displayName':this.displayName}
  //             //let promise = ref.push(node);
  //             //let key = promise.key
  //           } else {
  //             let currentTime = this.authService.getCurrentTime();
  //             //this.authService.insertDataWithFolder(this.uid,folderId,this.video.videoId);
  //             let ref = this.authService.getRef('/userProfile/'+this.uid+'/public_folders/'+folderId+'/'+this.video.videoId);
  //             ref.set({'timestamp':currentTime});
  //             ref.once('value').then(snapshot=>{
  //               let timestamp = snapshot.val().timestamp * -1
  //               ref.update({'timestamp':timestamp});
  //             })
  //           }
  //         } else {
  //           // console.log('no folder is being choosed')
  //         }
  //       })
  //     });
  //   } else {
  //     // console.log('you need login first');


  //     // update timestamp to negative value for the purpose of descending sorted order
  //     // const update_ref = ref.child(key);
  //     // update_ref.once('value').then(snapshot=>{
  //     //   console.log(snapshot.key);
  //     //   node['key'] = snapshot.key;
  //     //   let timestamp = snapshot.val().timestamp * -1
  //     //   update_ref.update({'timestamp':timestamp});
  //     // })
  //   }
  // }

  /* 1. open a new window of google map with cuurent location and destination
  */
  // navigation() {
  //   // console.log('navigation is called');
  //   this.geolocation.getCurrentPosition().then(position=>{
  //     let origin = position.coords.latitude + ',' + position.coords.longitude;
  //     let des = this.video.locations[0].lat + ',' + this.video.locations[0].lng;
  //     this.navCtrl.push(NavigationPage,
  //       {'start_point':origin,
  //        'end_point':des,
  //        'lat':position.coords.latitude,
  //        'lng':position.coords.longitude
  //       });
  //     // this.directionsDisplay.setMap(this.map);
  //     // this.directionsDisplay.setPanel(this.directionsPanel.nativeElement);
  //     // this.directionsService.route({
  //     //   origin: origin,
  //     //   destination: des,
  //     //   travelMode: 'DRIVING'
  //     // }, (response, status) => {
  //     //   if (status === 'OK') {
  //     //     this.directionsDisplay.setDirections(response);
  //     //   } else {
  //     //     window.alert('Directions request failed due to ' + status);
  //     //   }
  //     // });
  //   }).catch(err=>{
  //     this.presentAlert()
  //   console.log(err);
  //   })

  //   //
  //   //   let url = 'https://www.google.com/maps/dir/?api=1&origin='+origin+'&destination='+des+'&travelmode=driving';
  //   //   window.open(url);
  //   // }).catch(err=>{
  //   //   //console.log(err);
  //   // });
  // }
  // Present alert when user doesn't enable location service.
  // presentAlert(){
  //   let alert = this.alertCtrl.create({
  //     title: 'We can\'t get your current location. Please allow location service in your phone settings.',
  //     buttons: [
  //       {
  //         text: 'Ok',
  //         role: 'Ok',
  //         handler: data => {
  //         }
  //       },
  //     ]
  //   });
  //   alert.present();
  // }
  /* 1. navigate to AddVideo_2Page with current video's info for the purpose of updating
  */

  // edit_video() {
  //   if(this.uid){
  //     var copy = JSON.parse(JSON.stringify(this.video))
  //     this.navCtrl.push(AddVideo_2Page,{'update':copy});
  //   }
  // }
  /* 1. navigate to EditorsPage with current videoId and the first postor of the video
  */
  // go_all_editors() {
  //   if(this.uid){
  //     this.navCtrl.push(EditorsPage,{'videoId':this.video.videoId,'postor':this.postors});
  //   }
  // }
  // get_liked() {
  //   if(this.uid){
  //     this.authService.isLiked(this.video.videoId+'/'+this.uid).then(result=>{
  //       // console.log(result);
  //       this.like = 'placetellBlue';
  //       this.fire_like = 'placetellBlue';
  //     }).catch(err=>{
  //       console.log(err);
  //       this.like = 'light';
  //       this.fire_like = 'light';
  //     })
  //   } else {
  //     this.like = 'light';
  //   }
  // }
  get_liked_count() {
    this.authService.getRef('videosLike/' + this.video.videoId).once('value').then(snapshot => {
      this.like_count = snapshot.numChildren();
    })
  }
  // get_comments_count(){
  //   this.authService.getRef('comments/'+this.video.videoId).once('value').then(snapshot=>{
  //     snapshot.forEach(childrenSnapshot=>{
  //       this.comments_count = this.comments_count + 1;
  //       let temp = childrenSnapshot.val();
  //       if(temp.count){
  //         this.comments_count = this.comments_count + temp.count;
  //       }
  //     })
  //   })
  // }
  thumb_up(comment) {
    if (comment.thumb_up == 'grey') {
      comment.thumb_up = 'primary';
    } else {
      comment.thumb_up = 'grey';
    }
    // console.log(comment);
  }
  /* 1. Add comment to firebase. Each comment include content, userId, currentTime, and user's name
  *  2. Add this comments to UI
  */
  // add_comment(){
  //   if(this.uid){
  //     // push this comment object to firebase
  //     let currentTime = this.authService.getCurrentTime();
  //     let ref = this.authService.getRef('comments/'+this.video.videoId);
  //     let node = {'content':this.comment,'userId':this.uid,'timestamp':currentTime,'displayName':this.displayName}
  //     let promise = ref.push(node);
  //     let key = promise.key

  //     // update timestamp to negative value for the purpose of descending sorted order
  //     const update_ref = ref.child(key);
  //     update_ref.once('value').then(snapshot=>{
  //       // console.log(snapshot.key);
  //       node['key'] = snapshot.key;
  //       let timestamp = snapshot.val().timestamp * -1
  //       update_ref.update({'timestamp':timestamp});
  //     })

  //     // add this comment to the UI

  //     node['timestamp'] = '1 second ago';
  //     node['url'] = this.url;
  //     node['count'] = 0;
  //     node['sub'] = false;
  //     node['thumb_up'] = 'grey';
  //     this.comments.unshift(node);
  //     this.comment = "";
  //     this.comments_count = this.comments_count + 1;
  //   }
  // }
  /* 1. get all comments in descending order from Firebase
  *  2. each comment object includes timestamp, content, displayName, and userkey
  *  3. Then add commentkey, user'image, reply status, and sub_comment status.
  *  4. finall assgin to the instance variable fire_comments
  */
  // get_comments(){
  //   let date1 = new Date().getTime();
  //   let ref = this.authService.getRef('comments/'+this.video.videoId).orderByChild('timestamp');
  //   ref.once('value').then(snapshot=>{

  //     snapshot.forEach(data=>{

  //       let node = data.val();

  //       node['reply'] = false; //Default: set reply UI of this comment to false
  //       node['sub'] = false; //Default: set UI of this comment's sub_comment to false
  //       node['key'] = data.key;

  //       let date = data.val()['timestamp']*-1;
  //       let difference = date1 - date;
  //       const secondsDifference = Math.floor(difference/1000);
  //       const minutesDifference = Math.floor(secondsDifference/60);
  //       const hoursDifference = Math.floor(minutesDifference/60)
  //       const daysDifference = Math.floor(hoursDifference/24);

  //       node['timestamp'] = secondsDifference + ' seconds ago';

  //       if(minutesDifference>0) node['timestamp'] = minutesDifference + ' minutes ago';
  //       if(hoursDifference>0) node['timestamp'] = hoursDifference + ' hours ago';
  //       if(daysDifference>0) node['timestamp'] = daysDifference + ' days ago';

  //       this.authService.download(node['userId']).then(url=>{
  //         node['url'] = url;
  //       }).catch(err=>{
  //         node['url'] = 'assets/imgs/img.jpg';
  //       })
  //       /** need to update for pull from firebase*/
  //       node['thumb_up'] = 'grey';

  //       this.fire_comments.push(node);
  //     })
  //   })
  // }
  /* 1. Each time user scroll down the page, load 10 more comments from fire_comments if it exists
  *  2. Set the loading time to be at least 1 sec
  */
  // doInfinite(infiniteScroll) {
  //   // console.log('Begin async operation');

  //   setTimeout(() => {
  //     for (let i = this.count; i < this.count+10; i++) {
  //       if(this.fire_comments[i]){
  //         this.comments.push(this.fire_comments[i]);
  //       }
  //     }
  //     this.count+=10;
  //     // console.log('Async operation has ended');
  //     infiniteScroll.complete();
  //   }, 1000);
  // }
  /* 1. Set reply UI of this comment to true
  */
  // reply(comment) {
  //   // console.log(comment);
  //   comment.reply = true;
  // }
  /* 1. Add sub_comments to Firebase under the path of video/comment/sub_comment
  *  2. Update the number of sub_comment to Firebase under the path of video/comment
  *  3. Add this sub_comments to UI
  */
  // reply_comment(comment,index) {
  //   // console.log(comment,index);
  //   if(this.uid){
  //     // push this comment object to firebase
  //     // 1.upload to sub
  //     let currentTime = this.authService.getCurrentTime();
  //     let ref = this.authService.getRef('commentsSub/'+this.video.videoId+'/'+comment.key);
  //     let node = {'content':this.rep_comment,'userId':this.uid,'timestamp':currentTime,'displayName':this.displayName}
  //     ref.push(node);

  //     // 2.update number of sub_comment
  //     let ref2 = this.authService.getRef('comments/'+this.video.videoId+'/'+comment.key);
  //     ref2.once('value').then(snapshot=>{
  //       if(snapshot.val().count){
  //         ref2.update({'count':snapshot.val()['count']+1});
  //       }else{
  //         ref2.update({'count':1});
  //       }
  //     })

  //     comment.reply = false; //reply comment is already submitted thus set reply UI of this comment to true
  //     this.rep_comment = ""; //reply comment is already submitted thus initialize its value to empty

  //     // Add to UI
  //     node['timestamp'] = '1 second ago';
  //     node['url'] = this.url

  //     this.comments_count = this.comments_count + 1;
  //     /* if sub_comment UI is not opened, update comment.count
  //     *  else update this.show_sub_comments[index]
  //     */
  //     if(comment.sub==false){
  //       if(comment.count){
  //         comment.count +=1;
  //       } else {
  //         comment.count = 1;
  //       }
  //     } else {
  //       if(this.show_sub_comments[index]!=0){
  //         this.show_sub_comments[index] += 1;
  //       }else {
  //         this.show_sub_comments[index] = 1;
  //       }
  //     }
  //     // Store array of sub_comment for a specify comment by utilize this comment's key
  //     if(this.sub_comments[comment.key]){
  //       this.sub_comments[comment.key].push(node);
  //     } else {
  //       this.sub_comments[comment.key] = [];
  //       this.sub_comments[comment.key].push(node);
  //     }
  //   }
  // }
  /* get all sub_comment
  */
  // view_sub_comment(comment,i) {
  //   if(this.uid){
  //     this.show_sub_comments[i]=comment.count;
  //     comment.count=0;
  //     let date1 = new Date().getTime();
  //     comment.sub = true;
  //     let ref = this.authService.getRef('commentsSub/'+this.video.videoId+'/'+comment.key);
  //     ref.once('value').then(snapshot=>{
  //       this.sub_comments[comment.key] = [];
  //       snapshot.forEach(data=>{
  //         //console.log(data.val());
  //         let node = data.val();
  //         let date = data.val()['timestamp'];
  //         let difference = date1 - date;
  //         const secondsDifference = Math.floor(difference/1000);
  //         const minutesDifference = Math.floor(secondsDifference/60);
  //         const hoursDifference = Math.floor(minutesDifference/60)
  //         const daysDifference = Math.floor(hoursDifference/24);

  //         node['timestamp'] = secondsDifference + ' seconds ago';
  //         if(node['timestamp']<0) node['timestamp'] = '1 second ago';
  //         if(minutesDifference>0) node['timestamp'] = minutesDifference + ' minutes ago';
  //         if(hoursDifference>0) node['timestamp'] = hoursDifference + ' hours ago';
  //         if(daysDifference>0) node['timestamp'] = daysDifference + ' days ago';

  //         this.authService.download(node['userId']).then(url=>{
  //           node['url'] = url;
  //         }).catch(err=>{
  //           node['url'] = 'assets/imgs/img.jpg';
  //         })
  //         this.show_sub_comments.push(0);
  //         this.sub_comments[comment.key].push(node);
  //       })
  //     })
  //   }
  // }
  /* 1. Set UI of this comment's sub_comment to false
  *  2. Switch comment.count value for the purpose of showing hidden and view all system
  */
  // hidden_sub_comment(comment,index) {
  //   comment.sub = false;
  //   comment.count=this.show_sub_comments[index];
  //   this.show_sub_comments[index]=0;
  // }
  /* 1. set reply UI of this comment to false
  */
  // cancel(comment) {
  //   this.rep_comment = "";
  //   comment.reply = false;
  // }
  // parse_location(address){
  //   let result = '';
  //   for(let i = 0 ; i < 10 ; i++){
  //     result = result + address + '.  ';
  //   }
  //   return result;
  // }
  // report() {
  //   if(this.uid){
  //     this.navCtrl.push(ReportComponent,{'videoId':this.video.videoId,'userId':this.uid})
  //     // let option = {
  //     //   'enableBackdropDismiss':false
  //     // }
  //     // let popover = this.popoverCtrl.create(ReportComponent,{'videoId':this.video.videoId,'userId':this.uid},option);
  //     // popover.present();
  //   } else {
  //     // console.log('do nothing becasue nobody log in')
  //   }
  // }
  // report_comment(comment,i) {
  //   if(this.uid){
  //     this.navCtrl.push(ReportCommentComponent,{'videoId':this.video.videoId,'commentId':comment.key,'userId':this.uid})
  //     // let option = {
  //     //   'enableBackdropDismiss':false
  //     // }
  //     // let popover = this.popoverCtrl.create(ReportComponent,{'videoId':this.video.videoId,'userId':this.uid},option);
  //     // popover.present();
  //   } else {
  //     // console.log('do nothing becasue nobody log in')
  //   }
  // }

  // share(){
  //   let actionSheet = this.actionSheetCtrl.create({
  //       title: 'Sharing',
  //       buttons: [
  //         {
  //           text: 'Facebook',
  //           handler: () => {
  //             this.socialSharing.shareViaFacebook("",null,'www.placetell.com');
  //           }
  //         },
  //         {
  //           text: 'Twitter',
  //           handler: () => {
  //             let message = 'I found the following video at '+this.video.locations[0].address + ', in PlaceTell.com';
  //             let file = 'https://img.youtube.com/vi/'+this.video.videoId+'/hqdefault.jpg';
  //             this.socialSharing.shareViaTwitter(message,file,'www.placetell.com');
  //           }
  //         },
  //         {
  //           text: 'Cancel',
  //           role: 'cancel',
  //         }
  //       ]
  //     });
  //     actionSheet.present();
  // }
  switchMapType() {
    if (this.mapType == 'Satellite') {
      // console.log('here clicked')
      this.map.setMapTypeId('hybrid');
      this.mapType = 'Road'
    } else if (this.mapType == 'Road') {
      this.map.setMapTypeId('roadmap');
      this.mapType = 'Satellite'
    }
  }
  // parse_distance(distance){
  //   return Math.round(distance*10)/10
  // }
  // popBack(){

  //   this.navCtrl.pop()
  // }
  getRelatedVideos(videoId) {
    function compare(a, b) {
      if (a.score < b.score)
        return 1;
      if (a.score > b.score)
        return -1;
      if (a.score = b.score) {
      }
      return 0;
    }
    function shuffle(a) {
      var j, x, i;
      for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
      }
      return a;
    }
    this.relatedVideos = []
    let index = 0
    let relatedVideosPrep = []
    if (videoId != undefined && videoId.trim().length > 0) {
      this.authService.getRef('videoRelated/' + videoId).once('value').then(res => {
        res.forEach(video => {
          index++
          if (video.val().score > 20) {
            let node = { 'videoId': video.key, 'score': video.val().score }
            relatedVideosPrep.push(node)
          }

          if (index == res.numChildren()) {
            relatedVideosPrep.sort(compare)
            if (relatedVideosPrep.length > 100) {
              relatedVideosPrep.splice(100)
            }
            relatedVideosPrep = relatedVideosPrep.slice(0, 5).concat(shuffle(relatedVideosPrep.slice(5)))
            for (let i = 0; i < Math.min(relatedVideosPrep.length, 30); i++) {
              this.authService.getRef('videos/' + relatedVideosPrep[i]['videoId']).once('value').then(res2 => {
                this.relatedVideos.push(res2.val())
              })
            }
            // console.log(this.relatedVideos)
          }
        })
      })
    }
  }

  parse(viewCount) {
    if (viewCount / 1000000000 >= 1) {
      return Math.round(viewCount / 1000000000) + 'B';
    } else if (viewCount / 1000000 >= 1 && viewCount / 1000000000 < 1) {
      return Math.round(viewCount / 1000000) + 'M';
    } else if (viewCount / 1000 >= 1 && viewCount / 1000000 < 1) {
      return Math.round(viewCount / 1000) + 'K';
    } else {
      return Math.round(viewCount);
    }
  }
  open_link(url) {
    // window.location.href = url
    window.open(url, "_blank");
    // this.add_click_history('open_url', url)
  }
  close() {
    // this.add_click_history('close-video', '')
    this.modalCtrl.dismiss()
    //this.navCtrl.setRoot(TabsPage);
  }
  play_video_in_page(video) {
    this.video = video
    this.videoId = this.video.videoId
    this.init_variables();
    this.get_liked_count();
    this.getRelatedVideos(this.video.videoId)
    this.content.scrollToTop();
    this.authService.addClickHistory('video',this.videoId,this.video.title,'open-video-in-page',this.video.videoId)
 
  }
}
