import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class LocalService {

  public history: any;
  constructor(
    private storage: Storage
  ) { }
  getData() {
    return this.storage.get('history');
  }

  save(data) {
    this.storage.set('history', data);
  }

  clear() {
    this.storage.clear();
  }

  addVideoWatchHistoryToLocalStorage(video) {
    this.getData().then(local_videos => {
      if (local_videos) {
        // if localStorage is non-empty, assign the local_videos to this.items
        this.history = local_videos;
        if (this.notContain(video, this.history)) {
          //if this.video is not existed, push and save
          this.history.push(video);
          this.save(this.history);
        }
        else {
          //if this.video is already existed in localStorage, update and save
          this.save(this.history);
        }
      } else {
        // if localStorage is empty, create an empty array and then save it
        this.history = [];
        this.save(this.history);
      }
    }) 
  }
  notContain(item, items): boolean {
    for (let i = 0; i < items.length; i++) {
      if (item.videoId == items[i].videoId) {
        items[i] = item;
        return false;
      }
    }
    return true;
  }
}
