import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as firebase from 'firebase/app';
// import 'rxjs/add/operator/map'
import 'rxjs/add/operator/map';
import { Platform, ModalController, PopoverController, Events } from '@ionic/angular';
import { GeoFire } from 'geofire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAuth } from '@angular/fire/auth';
// import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireDatabase } from '@angular/fire/database';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private storageRef: any;
  public uIP: any;
  public tagList: any;
  public tagsSuffixList: any;

  public cityList: any;
  public cityListTop: any;
  public cityListReady: any;

  public mediaList: any;
  public mediaSuffixList: any;

  public userDeviceType: any;

  public userImg: any;
  public loginStatus: boolean;
  public loginStatusReady: boolean;

  constructor(
    public http: HttpClient,
    public platform: Platform,
    public modalCtrl: ModalController,
    public popoverCtrl: PopoverController,
    private afAuth: AngularFireAuth,
    private afDb: AngularFireDatabase,
    private events: Events,
  ) {
    this.uIP = ''
    this.storageRef = firebase.storage().ref();
    this.getVisitorIp()
    this.getUserMeta()
    this.tagList = []
    this.tagsSuffixList = []
    this.cityListTop = []
    this.cityList = []
    this.cityListReady = false;
    this.getTagSuffixList()
    this.getMediaSuffixList()
    this.readCityJson()
    this.userImg = 'assets/imgs/transparent.png'
    this.loginStatus = false
    this.loginStatusReady = false
    this.checkUserLoginStatus()
  }

  getCurrentUser() {
    return this.afAuth.auth.currentUser;
  }

  getUserInfo() {
    let user = this.afAuth.auth.currentUser;

    return new Promise((resolve, reject) => {
      if (user) {
        let ref = this.afDb.database.ref('userProfile').child(user.uid).child('user_info');
        ref.once('value').then(snapshot => {
          let node = {}
          node['loginStatus'] = true
          let userProfile = snapshot.val();
          if (userProfile.displayName) {
            node['displayName'] = userProfile.displayName;

          }
          if (snapshot.val()['user_img']) {
            node['imgUrl'] = snapshot.val()['user_img']
          } else {
            node['imgUrl'] = 'assets/imgs/transparent.png'
          }
          resolve(node)
        }).catch(err => {
          console.log(err);
        });
      } else {
        let node = {}
        node['loginStatus'] = false
        node['displayName'] = ''
        node['imgUrl'] = 'assets/imgs/transparent.png'
        resolve(node)
      }

    })
  }

  checkUserLoginStatus() {
    this.afAuth.auth.onAuthStateChanged(user => {
      if (user && (user.emailVerified || (user.providerData[0].providerId === 'facebook.com'))) {
        let ref = this.afDb.database.ref('userProfile').child(user.uid).child('user_info');
        ref.once('value').then(snapshot => {
          if (snapshot.val()['user_img']) {
            this.userImg = snapshot.val()['user_img']
          } else {
            this.userImg = 'assets/imgs/transparent.png'
          }
          this.loginStatus = true
          this.loginStatusReady = true

        })
      } else {
        // this.loginStatus = false 
        this.loginStatus = false
        this.loginStatusReady = true




      }
    });
  }
  loginWithGoogle() {
    return this.afAuth.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then(newUser => {
        // console.log(newUser);
        let uid = newUser.user.uid;
        let displayName = newUser.user.displayName;
        let email = newUser.user.email;
        let ref = this.afDb.database.ref('/userProfile').child(newUser.user.uid);
        ref.once('value').then(snapshot => {
          // console.log(snapshot.exists());
          // console.log('uid', uid);
          // console.log('displayName', displayName);
          if (snapshot.exists() == false) {
            let ref2 = this.afDb.database.ref('/userProfile').child(uid);
            ref2.child("user_info").set({ email: email, displayName: displayName });
            ref2.child('folders/My Edits').set({ 'name': 'My Edits' });
            ref2.child('folders/My Favorites').set({ 'name': 'My Favorites' });
            ref2.child('folders/My Places').set({ 'name': 'My Places' });
            ref2.child('folders/My Tags').set({ 'name': 'My Tags' });
            ref2.child('folders/My Tours').set({ 'name': 'My Tours' });
            ref2.child('folders/Recently Watched').set({ 'name': 'Recently Watched' });
            ref2.child('public_folders/High Light').set({ 'name': 'High Light' });
          }
        })
      });
  }
  loginWithFacebook() {
    return this.afAuth.auth.signInWithPopup(new firebase.auth.FacebookAuthProvider())
      .then(newUser => {
        console.log(newUser);
        let uid = newUser.user.uid;
        let displayName = newUser.user.displayName;
        let email = newUser.user.email;
        let ref = this.afDb.database.ref('/userProfile').child(newUser.user.uid);
        ref.once('value').then(snapshot => {
          console.log(snapshot.exists());
          console.log('uid', uid);
          console.log('displayName', displayName);
          if (snapshot.exists() == false) {
            let ref2 = this.afDb.database.ref('/userProfile').child(uid);
            ref2.child("user_info").set({ email: email, displayName: displayName });
            ref2.child('folders/My Edits').set({ 'name': 'My Edits' });
            ref2.child('folders/My Favorites').set({ 'name': 'My Favorites' });
            ref2.child('folders/My Places').set({ 'name': 'My Places' });
            ref2.child('folders/My Tags').set({ 'name': 'My Tags' });
            ref2.child('folders/Recently Watched').set({ 'name': 'Recently Watched' });
            ref2.child('public_folders/High Light').set({ 'name': 'High Light' });
          }
        })

      });
  }

  logoutUser() {
    return this.afAuth.auth.signOut();
  }

  getRef(path) {
    // return this.afDb.database.ref(path);
    return firebase.database().ref(path)
  }

  download(uid) {
    return new Promise((resolve, reject) => {

      this.storageRef.child('uploads/' + uid).getDownloadURL().then(url => {
        if (url) {
          //console.log(url);
          resolve(url);
        } else {
          reject(false);
        }
      }).catch(err => {
        //console.log(err);
        reject(false);
      })
    })
  }
  download2(uid) {
    return new Promise((resolve, reject) => {
      this.storageRef.child('story/' + uid + '.jpg').getDownloadURL().then(url => {
        if (url) {
          //console.log(url);
          resolve(url);
        } else {
          reject(false);
        }
      }).catch(err => {
        //console.log(err);
        reject(false);
      })
    })
  }
  download3(uid) {
    return new Promise((resolve, reject) => {
      this.storageRef.child('tagImage/' + uid + '.jpg').getDownloadURL().then(url => {
        if (url) {
          // console.log(url);
          resolve(url);
        } else {
          reject(false);
        }
      }).catch(err => {
        //console.log(err);
        reject(false);
      })
    })
  }
  download5(uid) {
    return new Promise((resolve, reject) => {
      this.storageRef.child('mediaImage/' + uid + '.jpg').getDownloadURL().then(url => {
        if (url) {
          //console.log(url);
          resolve(url);
        } else {
          reject(false);
        }
      }).catch(err => {
        //console.log(err);
        reject(false);
      })
    })
  }
  getCurrentTime() {
    return firebase.database.ServerValue.TIMESTAMP;
  }
  getVisitorIp() {
    // console.log('get ip') 
    this.http.get('https://jsonip.com').subscribe(res => {
      this.uIP = res['ip']
      // console.log('uIP',this.uIP)
    })
  }

  getUserMeta() {
    this.userDeviceType = ''
    if (this.platform.is('mobile')) {
      this.userDeviceType += 'mobile, '
    }
    if (this.platform.is('desktop')) {
      this.userDeviceType += 'web-desktop, '
    }
    if (this.platform.is('ios')) {
      this.userDeviceType += 'web-ios, '
    }
    if (this.platform.is('android')) {
      this.userDeviceType += 'web-android, '
    }
    if (this.platform.is('iphone')) {
      this.userDeviceType += 'iphone, '
    }
    if (this.platform.is('ipad')) {
      this.userDeviceType += 'ipad, '
    }

    // console.log(this.userDeviceType)
    // console.log(this.userScreenHeight,this.userScreenWidth)
  }


  getTagSuffixList() {
    this.tagsSuffixList = []
    firebase.database().ref('tagsSuffix').once('value').then(res => {
      res.forEach(tag => {
        this.tagsSuffixList.push({
          'suffix': tag.key,
          'key': tag.val()
        })
      })
    })
  }
  getTagList() {
    this.tagList = []
    firebase.database().ref('tagsList').once('value').then(res => {
      res.forEach(tag => {
        this.tagList.push({
          'key': tag.key,
          'name': tag.val()
        })
      })
    })
  }
  getMediaSuffixList() {
    this.mediaList = []
    this.mediaSuffixList = []
    firebase.database().ref('mediaSuffix').once('value').then(res => {
      res.forEach(medium => {
        this.mediaSuffixList.push({
          'suffix': medium.key,
          'key': medium.val()
        })
      })
    })
    //   firebase.database().ref('mediaList').once('value').then(res => {
    //     res.forEach(medium => {
    //       this.mediaList.push({
    //         'key': medium.key,
    //         'name': medium.val()
    //       })
    //     })
    //   })
  }
  /** init function: get all cities from local file */
  readCityJson() {
    this.cityList = [];
    this.cityListTop = [];
    this.http.get('assets/file/citylist100.json')
      .subscribe(
        data => {
          this.cityListTop = data;
          // this.cityListReady = true; 

        },
        error => {
          console.log(error);
        }
      );
    this.http.get('assets/file/data.json')
      .subscribe(
        data => {
          this.cityList = data;
          this.cityListReady = true;

        },
        error => {
          console.log(error);
        }
      );
  }

  // get nearby videos from geofire, geofire1 and geofire2
  getLocation(radius: number, coords: Array<number>) {
    let geoFire = new GeoFire(firebase.database().ref().child('geofire'));
    return geoFire.query({
      center: coords,
      radius: radius * 1.609
    });
  }
  getLocation1(radius: number, coords: Array<number>) {
    let geoFire = new GeoFire(firebase.database().ref().child('geofire1'));
    return geoFire.query({
      center: coords,
      radius: radius * 1.609
    });
  }
  getLocation2(radius: number, coords: Array<number>) {
    let geoFire = new GeoFire(firebase.database().ref().child('geofire2'));
    return geoFire.query({
      center: coords,
      radius: radius * 1.609
    });
  }


  getWikipedia(title) {
    title = title.replace(/ /g, '_')
    return this.http.get('https://en.wikipedia.org/api/rest_v1/page/summary/' + title)
  }

  searchWikipedia(keyword) {
    keyword = keyword.replace(/ /g, '_')
    return this.http.get('https://en.wikipedia.org/w/api.php?action=query&list=search&srsearch=' + keyword + '&format=json')
  }
  // getPlaceDetail(id){
  //   id = 'ChIJ-f7-atVr04kRIzxTkdcibi0'
  //   this.http.get('https://maps.googleapis.com/maps/api/place/details/json?placeid='+id+'&fields=name,rating,formatted_phone_number&key=AIzaSyC0ftkTR0SHyIUVdXrL0ivxooj4VfcdyME').subscribe(res=>{
  //     console.log(res)
  //   })
  // }


  getEventBriteForLocation(lat, lng) {
    return this.http.get("https://www.eventbriteapi.com/v3/events/search/?token=NY7VEHOOH3MLCLQN2L6N&location.longitude=" + lng + "&location.latitude=" + lat + "&location.within=30km&categories=109,110&expand=venue")
  }
  getEventBriteForEvent(eventId) {
    return this.http.get("https://www.eventbriteapi.com/v3/events/" + eventId + "/?token=NY7VEHOOH3MLCLQN2L6N&expand=venue")
  }
  addViewHistory(pageName, pageKey, pageTitle) {
    setTimeout(() => {
      if (this.uIP != '74.77.85.36') {
        let currentTime = this.getCurrentTime();
        let node = {
          'timestamp': currentTime,
          'ip': this.uIP,
          'page': pageName,
          'subpage': pageKey,
          'pagename': pageTitle,
          'action': 'pageEnter',
          'actionKey': '',
          'source': '',
          'device': this.userDeviceType,
          'screenWidth': screen.width,
          'screenHeight': screen.height
        }
        // console.log(node)

        this.getRef('/admin/viewHistory/all/').push(node)
      } else {
        // console.log('visit from home')
      }
    }, 2000)
  }


  addClickHistory(pageName, pageKey, pageTitle, actionType, actionKey) {
    setTimeout(() => {
      if (this.uIP == undefined) {
        this.uIP = 'unknown'
      }
      if (this.uIP != '74.77.85.36') {
        let currentTime = this.getCurrentTime();
        let node = {
          'timestamp': currentTime,
          'ip': this.uIP,
          'page': pageName,
          'subpage': pageKey,
          'pagename': pageTitle,
          'action': actionType,
          'actionKey': actionKey,
          'source': '',
          'device': this.userDeviceType,
          'screenWidth': screen.width,
          'screenHeight': screen.height
        }
        this.getRef('/admin/clickHistory/').push(node)
      } else {
        // console.log('click from home')
      }
    }, 2000)
  }
}
