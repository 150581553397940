import { Component, OnInit, Input } from '@angular/core';
import { NavController } from '@ionic/angular';

declare var google
@Component({
  selector: 'app-videocard-list',
  templateUrl: './videocard-list.component.html',
  styleUrls: ['./videocard-list.component.scss']
})
export class VideocardListComponent implements OnInit {
  @Input() video: any;
  @Input() pageType: any;

  categoryBackground = {
    'News': '#FF7F50',
    'Travel': '#1bb55b',
    '360': '#FF7F50',
    'Food': '#FF5959',
    'Funny': '#FF7F50',
    'Shopping': '#FF5959',
    'Movie': '#f7cd43',
    'TV': '#f7cd43',
    'Music': '#1bb0b5',
    'Sports': '#FF7F50',
    'Gaming': '#FF7F50',
    'Events': '#FF7F50',
    'Life': '#FF7F50',
    'Vlog': '#FF7F50',
    'People': '#FF7F50',
    'Animals': '#FF7F50',
    'Nature': '#1bb55b',
    'Culture': '#FF7F50',
    'History': '#FF7F50',
    'Arts': '#FF7F50',
    'Art': '#FF7F50',
    'Tech': '#FF7F50',
    'LifeStyle': '#FF7F50',
  }
  public devWidth = window.innerWidth;
  constructor(public navCtrl: NavController) {
  }

  ngOnInit() {

    console.log(this.video)
  }
  open_story(story) {
    this.navCtrl.navigateRoot('story/' + story.key)
  }
  parse(viewCount) {
    if (viewCount / 1000000000 >= 1) {
      return Math.round(viewCount / 1000000000) + 'B';
    } else if (viewCount / 1000000 >= 1 && viewCount / 1000000000 < 1) {
      return Math.round(viewCount / 1000000) + 'M';
    } else if (viewCount / 1000 >= 1 && viewCount / 1000000 < 1) {
      return Math.round(viewCount / 1000) + 'K';
    } else {
      return Math.round(viewCount);
    }
  }
  // OnMouseIn(index) {
  //   this.markers[index].setIcon({
  //     url: 'assets/imgs/map_pin_1.svg',
  //     scaledSize: new google.maps.Size(60, 60)
  //   })
  //   this.markers[index].setZIndex(google.maps.Marker.MAX_ZINDEX + 1);
  // }
  // OnMouseOut(index) {
  //   this.markers[index].setIcon({
  //     url: 'assets/imgs/map_pin_0.svg',
  //     scaledSize: new google.maps.Size(50, 50)
  //   })
  //   this.markers[index].setZIndex(index);
  // }
}
