import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AlertController, NavController, PopoverController, Events, } from '@ionic/angular'
import { Alert } from 'selenium-webdriver';
import { AuthService } from '../../services/auth.service';
import { LoginComponent } from '../login/login.component';
import * as $ from "jquery";

@Component({
  selector: 'app-header-normal',
  templateUrl: './header-normal.component.html',
  styleUrls: ['./header-normal.component.scss']
})

export class HeaderNormalComponent implements OnInit {
  @Input() page: any;

  public searchSectionStyle: any;
  private isSearchOn: any;
  private visitorIP: any;
  private trending_tags: any;
  private citySuggestion: any;
  private autocompleteTag: any;
  private autocomplete_peopleItems: any;
  private autocomplete_cityItems: any;

  private tags = [];
  private searchInput: any;
  private searchbarPlaceholder: any;
  private message: any;
  private userDevice: any;

  private loginStatus: boolean;
  private userDisplayName: any;
  private userImg: any;
  private accountPanelStyle: any;

  constructor(
    public alertCtrl: AlertController,
    public authService: AuthService,
    public navCtrl: NavController,
    private popoverController: PopoverController,
    private events: Events) {

    $(document).mouseup((e) => {
      if (this.page == 'normal') {
        var container = $("#userPanelNormal");
        // if the target of the click isn't the container nor a descendant of the container 
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          if (this.accountPanelStyle) {
            this.accountPanelStyle = false
          }
        }
      }
      else if (this.page == 'homepage') {
        var container = $("#userPanelHome");
        // if the target of the click isn't the container nor a descendant of the container
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          if (this.accountPanelStyle) {
            this.accountPanelStyle = false
          }
        }
      }
    });
    if (this.authService.uIP) {
      this.visitorIP = this.authService.uIP
    } else {
      setTimeout(() => {
        this.visitorIP = this.authService.uIP
        if (this.visitorIP == undefined) {
          this.visitorIP = 'unknown'
        }
      }, 500)
    }

    this.searchSectionStyle = {
      'height': '0',
      'overflow': 'hidden'
    }
    this.isSearchOn = false;
    this.autocompleteTag = { input: '', key: '' };
    this.autocomplete_peopleItems = [];
    this.trending_tags = [];
    this.citySuggestion = []
    this.searchInput = ''
    this.searchbarPlaceholder = ''
    this.message = '';
    this.accountPanelStyle = false
    this.getTrendingTags()
    this.getTopCities()
  }

  ngOnInit() {
    setTimeout(() => {
      let userInfo = this.authService.getUserInfo().then(userInfo => {
        if (userInfo['loginStatus'] == true) {
          this.loginStatus = true
          this.userDisplayName = userInfo['displayName']
          this.userImg = userInfo['imgUrl']
        } else {
          this.loginStatus = false
          this.userDisplayName = userInfo['displayName']
          this.userImg = userInfo['imgUrl']
        }
      })

    }, 1000)
  }
  async coming_soon() {
    const alert = await this.alertCtrl.create({
      header: 'Oops!',
      // subHeader: 'Subtitle',
      message: 'This page is coming soon! Please check it out in a few more days.',
      buttons: ['OK']
    })
    await alert.present()
  }
  add_click_history(type, key) {
    if (this.visitorIP == undefined) {
      this.visitorIP = 'unknown'
    }
    if (this.visitorIP != '74.77.85.36') {
      let currentTime = this.authService.getCurrentTime();
      let node = {
        'timestamp': currentTime,
        'ip': this.visitorIP,
        'page': 'header',
        'subpage': '',
        'pagename': '',
        'action': type,
        'actionKey': key,
        'source': '',
        'device': this.userDevice,
        'screenWidth': screen.width,
        'screenHeight': screen.height
      }
      this.authService.getRef('/admin/clickHistory/').push(node)
    }

  }
  open_link(url) {
    // window.location.href = url
    window.open(url, "_blank");
    this.add_click_history('button-app-download', url)
  }


  updateSearchPeopleResults() {
    if (this.searchInput == '') {
      this.autocomplete_peopleItems = [];
      this.autocomplete_cityItems = [];
      return;
    }
    // if(this.contain_special_character()==false){
    //   return;
    // }


    this.autocomplete_peopleItems = [];
    this.autocomplete_cityItems = [];

    let substrRegex = new RegExp(this.convertString(this.searchInput), 'i');
    // console.log(substrRegex)
    if (this.searchInput.length <= 2) {
      this.autocomplete_peopleItems = [];
    } else {
      for (let i = 0; i < this.authService.tagList.length; i++) {
        if (substrRegex.test(this.authService.tagList[i].name)) {
          let node = this.authService.tagList[i]
          node['index1'] = node.name.toUpperCase().indexOf(this.searchInput.trim().toUpperCase())
          node['index2'] = node['index1'] + this.searchInput.trim().length
          this.autocomplete_peopleItems.push(node);
        }
      }
      // console.log(this.autocomplete_peopleItems)
    }
    if (this.searchInput.length <= 2) {
      this.autocomplete_cityItems = [];
    } else {
      for (let i = 0; i < this.authService.cityList.length; i++) {
        if (substrRegex.test(this.authService.cityList[i].city)) {
          let node = this.authService.cityList[i]
          node['index1'] = node.city.toUpperCase().indexOf(this.searchInput.trim().toUpperCase())
          node['index2'] = node['index1'] + this.searchInput.trim().length
          this.autocomplete_cityItems.push(node);
        }
      }
    }
  }

  /** helper function: check if serarch contain special character */
  convertString(word) {
    let wordOutput = ''
    let specialCharacters = ['?', '.', '[', ']', '(', ')', '{', '}', '$', '^', '+', '|', '*', '\\']
    for (let i = 0; i < word.length; i++) {
      if (specialCharacters.indexOf(word[i]) != -1) {
        wordOutput += '\\' + word[i]
      } else {
        wordOutput += word[i]
      }
    }
    return wordOutput
  }
  // updateSearchPeopleResults(event){
  //   console.log('searchINput',this.searchInput) 
  //   this.autocompleteTagItems = []  
  //   if(this.tags.length == 0){ 
  //     this.tags = this.authService.tagList
  //   }
  //   if(this.searchInput.trim()!=''){
  //     let substrRegex = new RegExp(this.searchInput.trim(), 'i');
  //     for(let i = 0 ; i < this.tags.length ; i ++){
  //       if(substrRegex.test(this.tags[i].name)){
  //         let node = this.tags[i]
  //         node['index1'] = node.name.toUpperCase().indexOf(this.searchInput.trim().toUpperCase())
  //         node['index2'] = node['index1'] + this.searchInput.trim().length
  //         this.autocompleteTagItems.push(this.authService.tagList[i]);
  //       }
  //     }
  //     if(this.autocompleteTagItems.length==0){
  //       this.message = 'No matching tag found.'
  //     }
  //     console.log(this.autocompleteTagItems)
  //   }

  // }

  selectSearchCityResult(city) {
    let cityString = (city.city + '_' + city.state + '_' + city.country).replace(/ /g, '_').replace(/\//g, '_')
    this.navCtrl.navigateRoot('/city/' + cityString)
    this.add_click_history('open-city-from-search', cityString)
  }

  selectSearchPeopleResult(person) {
    this.navCtrl.navigateRoot('/tag/' + person.key)
    this.add_click_history('open-tag-from-search', person.key)
  }

  openSearch() {
    if (this.isSearchOn == false) {

      this.searchSectionStyle = { 'height': '100vh', 'overflow-y': 'scroll' }
      if (this.authService.tagList.length == 0) {
        this.authService.getTagList()
      }
      if (this.tags.length == 0) {
        this.tags = this.authService.tagList
      }

      if (this.trending_tags.length == 0) {
        this.getTrendingTags()

      }
      this.isSearchOn = true
      this.add_click_history('open-search', '')
    }
  }
  closeSearch() {
    // this.searchInput = '' 
    setTimeout(() => {
      this.searchSectionStyle = { 'height': '0', 'overflow': 'hidden' }
      setTimeout(() => {
        this.isSearchOn = false
      }, 200)
    }, 200)
    this.add_click_history('close-search', '')
  }
  openSearchNormal() {
    if (this.isSearchOn == false) {

      this.searchSectionStyle = { 'height': 'calc(var(--rem) * 30)', 'overflow-y': 'scroll' }
      if (this.authService.tagList.length == 0) {
        this.authService.getTagList()
      }
      if (this.tags.length == 0) {
        this.tags = this.authService.tagList
      }

      if (this.trending_tags.length == 0) {
        this.getTrendingTags()

      }
      this.isSearchOn = true
      this.add_click_history('open-search', '')
    }
  }
  getTrendingTags() {
    this.trending_tags = []
    this.authService.getRef('tagsTrending').once('value').then(res => {
      this.trending_tags = res.val()
      for (let i = 0; i < this.trending_tags.length; i++) {
        this.trending_tags[i]['img_small'] = 'assets/imgs/blank.jpg'
        this.get_img_tag(this.trending_tags[i])
        if (i < 2) {
          this.searchbarPlaceholder += this.trending_tags[i].name + ', ' + ' '
        }
        if (i == 2) {
          this.searchbarPlaceholder += this.trending_tags[i].name
        }
      }
    })
  }
  getTopCities() {
    function shuffle(a) {
      for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
      }
      return a;
    }
    let ref = this.authService.getRef('cityTop');
    ref.once('value').then(snapshot => {
      this.citySuggestion = snapshot.val()
      this.citySuggestion = shuffle(this.citySuggestion)
    })
  }
  get_img_tag(node) {
    let ref = this.authService.getRef('tagsWithSmallImg/' + node.key);
    ref.once('value').then(snapshot => {
      let img_url = snapshot.val();
      this.authService.download3(img_url).then(url => {
        // console.log('img url',url)
        node['img_small'] = url;
      }).catch(err => {
        console.log(err);
        node['img_small'] = 'assets/imgs/icon.png';
      });
    })
  }
  openTag(tag) {
    // console.log(tag);
    tag['img'] = 'assets/imgs/people/' + tag.name + '.jpg';
    this.add_click_history('open-tag-from-list', tag.key)
    this.navCtrl.navigateRoot('/tag/' + tag.key)
  }
  openCity(city) {
    let cityString = (city.name + '_' + city.state + '_' + city.country).replace(/ /g, '_').replace(/\//g, '_')
    this.navCtrl.navigateRoot('/city/' + cityString)
  }


  async openLogin(ev: any) {
    const popover = await this.popoverController.create({
      component: LoginComponent,
      event: ev,
      translucent: true,
      cssClass: 'login-popover'
    });
    popover.present();
  }

  openAccountPanel() {
    this.accountPanelStyle = !this.accountPanelStyle
  }

  logout() {
    this.authService.logoutUser()
    this.loginStatus = false
  }
}
