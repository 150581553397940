import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular'
import { HeaderNormalComponent } from './header-normal/header-normal.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { StorycardComponent } from './storycard/storycard.component';
import { FormsModule } from '@angular/forms';
import { VideocardListComponent } from './videocard-list/videocard-list.component';
import { LoginComponent } from './login/login.component';
import { ViewMapInAppComponent } from './view-map-in-app/view-map-in-app.component';

@NgModule({
  declarations: [
    HeaderNormalComponent,
    FooterComponent,
    StorycardComponent,
    VideocardListComponent,
    LoginComponent,
    ViewMapInAppComponent
  ],
  imports: [
    CommonModule,
    IonicModule.forRoot(),
    RouterModule,
    FormsModule,
  ],
  exports: [
    HeaderNormalComponent,
    FooterComponent,
    StorycardComponent,
    VideocardListComponent,
    LoginComponent,
    ViewMapInAppComponent

  ]
})
export class ComponentsModule { }
